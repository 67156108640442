import { inject }                                  from 'aurelia-framework';
import { BooleanStatus }                           from 'modules/administration/models/boolean-status';
import { CountiesRepository }                      from 'modules/administration/locations/counties/services/repository';
import { MaintenanceCompaniesRepository }          from 'modules/entities/maintenance-companies/services/repository';
import { MaintenanceCompany }                      from 'modules/entities/models/maintenance-company';
import { ParishesRepository }                      from 'modules/administration/locations/parishes/services/repository';
import { SingleFormSchema as LocationsFormSchema } from 'modules/administration/locations/reusable/single-form-schema';

@inject(CountiesRepository, LocationsFormSchema, MaintenanceCompaniesRepository, ParishesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        status_id: BooleanStatus.ACTIVE,
    };

    /**
     * Constructor
     *
     * @param countiesRepository
     * @param locationsFormSchema
     * @param maintenanceCompaniesRepository
     * @param parishesRepository
     */
    constructor(countiesRepository, locationsFormSchema, maintenanceCompaniesRepository, parishesRepository) {
        this.countiesRepository             = countiesRepository;
        this.locationsFormSchema            = locationsFormSchema;
        this.maintenanceCompaniesRepository = maintenanceCompaniesRepository;
        this.parishesRepository             = parishesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {MaintenanceCompany}
     */
    model() {
        let model = new MaintenanceCompany();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:  'text',
            key:   'name',
            id:    'maintenance_companies_name',
            label: 'form.field.designation',
            size:  8,
        };

        this.acronym = {
            type:  'text',
            key:   'acronym',
            id:    'maintenance_companies_acronym',
            label: 'form.field.acronym',
            size:  2,
        };

        this.code = {
            type:     'text',
            key:      'code',
            id:       'maintenance_companies_code',
            label:    'form.field.number',
            size:     2,
            required: false,
        };

        this.address = {
            type:     'text',
            key:      'address',
            id:       'maintenance_companies_address',
            label:    'form.field.address',
            size:     12,
            required: false,
        };

        this.zip_code = {
            type:     'text',
            key:      'zip_code',
            id:       'maintenance_companies_zip_code',
            label:    'form.field.zip-code',
            size:     4,
            required: false,
        };

        this.locality = {
            type:     'text',
            key:      'locality',
            id:       'maintenance_companies_locality',
            label:    'form.field.locality',
            size:     4,
            required: false,
        };

        this.phone_number = {
            type:     'text',
            key:      'phone_number',
            id:       'maintenance_companies_phone_number',
            label:    'form.field.phone-number',
            size:     4,
            required: false,
        };

        this.mobile_number = {
            type:     'text',
            key:      'mobile_number',
            id:       'maintenance_companies_mobile_number',
            label:    'form.field.mobile-number',
            size:     4,
            required: false,
        };

        this.fax_number = {
            type:     'text',
            key:      'fax_number',
            id:       'maintenance_companies_fax_number',
            label:    'form.field.fax-number',
            size:     4,
            required: false,
        };

        this.email = {
            type:     'email',
            key:      'email',
            id:       'maintenance_companies_email',
            label:    'form.field.email',
            size:     4,
            required: false,
        };

        this.tax_identification_number = {
            type:     'text',
            key:      'tax_identification_number',
            id:       'maintenance_companies_tax_identification_number',
            label:    'form.field.tax-number',
            size:     4,
            required: false,
        };

        this.status_id = {
            type:  'boolean-statuses-select',
            key:   'status_id',
            id:    'maintenance_companies_status_id',
            label: 'form.field.status',
            size:  4,
        };

        this.certificate_number = {
            type:  'text',
            key:   'certificate_number',
            id:    'maintenance_companies_certificate_number',
            label: 'form.field.certificate-number-dgeg',
            size:  4,
        };

        this.certificate_deadline = {
            type:            'material-ui-date-picker',
            key:             'certificate_deadline',
            id:              'maintenance_companies_certificate_deadline',
            label:           'form.field.certificate-deadline-dgeg',
            size:            4,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        let locationsSettings = {
            district: {
                id: 'maintenance_companies_district_id',
            },
            county:   {
                id: 'maintenance_companies_county_id',
            },
            parish:   {
                id: 'maintenance_companies_parish_id',
            },
        };

        return [
            [this.name, this.acronym, this.code],
            ...this.locationsFormSchema.withSettings(locationsSettings).schema(viewModel.model, false),
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status_id],
            [this.certificate_number, this.certificate_deadline],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            required:     false,
            remoteSource: this.maintenanceCompaniesRepository.getUsers.bind(this.maintenanceCompaniesRepository, viewModel.model.id),
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToMaintenanceCompany(false),
            action:     () => viewModel.redirectToRoute('entities.maintenance-companies.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}

import { BaseRepository } from 'modules/core/services/base-repository';

export class MaintenanceTechniciansRepository extends BaseRepository {

    baseUrl = 'entities/maintenance-technicians';

    /**
     * Returns all records
     *
     * @param entityId
     *
     * @returns {*}
     */
    allByEntity(entityId) {
        return this.httpClient.get(`${this.baseUrl}/all/${entityId}`);
    }

    /**
     * Returns active records
     *
     * @param entityId
     *
     * @returns {*}
     */
    activeByEntity(entityId) {
        return this.httpClient.get(`${this.baseUrl}/active/${entityId}`);
    }

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

}

import { bindable, inject }                 from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { BaseFormViewModel }                from 'base-form-view-model';
import { BooleanOption }                    from 'modules/administration/models/boolean-option';
import { ConfirmUserCreationDialog }        from 'resources/elements/html-elements/dialogs/confirm-user-creation-dialog';
import { DialogService }                    from 'aurelia-dialog';
import { FormSchema }                       from 'modules/entities/maintenance-technicians/form-schema';
import { MaintenanceTechniciansRepository } from 'modules/entities/maintenance-technicians/services/repository';

@inject(AppContainer, DialogService, FormSchema, MaintenanceTechniciansRepository)
export class CreateMaintenanceTechnician extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.create-new-record';
    @bindable formId      = 'entities-maintenance-technicians-creation-form';

    alert  = {};
    model  = {};
    schema = {};

    creating = true;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/entities/maintenance-technicians/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.maintenance-technicians.manage',
            'entities.maintenance-technicians.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema        = this.formSchema.schema(this);
            this.buttonsSchema = this.formSchema.buttonsSchema(this);

            this.appContainer.authenticatedUser.belongsToMaintenanceCompany(false) && this.assignEntity();
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .create(this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status === true ? this.appContainer.router.navigateToRoute('entities.maintenance-technicians.edit', { id: response.model.id }) : false);
    }

    /**
     * Submits view form, and requests the creation of a new user
     */
    submitAndCreateUser() {
        this.model.create_user_id = BooleanOption.YES;

        this.dialogService
            .open({ viewModel: ConfirmUserCreationDialog })
            .whenClosed(response => {
                if (! response.wasCancelled) {
                    return this.submit().then(() => this.model.create_user_id = null);
                }

                this.model.create_user_id = null;
            });

        return false;
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

    /**
     * Automatically assigns the record's entity
     */
    assignEntity() {
        this.model.entity_id = this.initialModel.entity_id = this.appContainer.authenticatedUser.user.entity.id;
    }

}

import { inject }                         from 'aurelia-framework';
import { MaintenanceCompaniesRepository } from 'modules/entities/maintenance-companies/services/repository';
import { MaintenanceCompany }             from 'modules/entities/models/maintenance-company';

@inject(MaintenanceCompaniesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param maintenanceCompaniesRepository
     */
    constructor(maintenanceCompaniesRepository) {
        this.maintenanceCompaniesRepository = maintenanceCompaniesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {MaintenanceCompany}
     */
    model() {
        let model = new MaintenanceCompany();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.designation',
            size:       8,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.acronym = {
            type:       'text',
            key:        'acronym',
            label:      'form.field.acronym',
            size:       2,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.number',
            size:       2,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.district = {
            type:       'text',
            key:        'district',
            label:      'form.field.district',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.county = {
            type:       'text',
            key:        'county',
            label:      'form.field.county',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.parish = {
            type:       'text',
            key:        'parish',
            label:      'form.field.parish',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.address = {
            type:       'text',
            key:        'address',
            label:      'form.field.address',
            size:       12,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.zip_code = {
            type:       'text',
            key:        'zip_code',
            label:      'form.field.zip-code',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.locality = {
            type:       'text',
            key:        'locality',
            label:      'form.field.locality',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.phone_number = {
            type:       'text',
            key:        'phone_number',
            label:      'form.field.phone-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.mobile_number = {
            type:       'text',
            key:        'mobile_number',
            label:      'form.field.mobile-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.fax_number = {
            type:       'text',
            key:        'fax_number',
            label:      'form.field.fax-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.email = {
            type:       'email',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.tax_identification_number = {
            type:       'text',
            key:        'tax_identification_number',
            label:      'form.field.tax-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.status = {
            type:       'text',
            key:        'status',
            label:      'form.field.status',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.certificate_number = {
            type:       'text',
            key:        'certificate_number',
            label:      'form.field.certificate-number-dgeg',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.certificate_deadline = {
            type:            'text',
            key:             'certificate_deadline',
            label:           'form.field.certificate-deadline-dgeg',
            size:            4,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        return [
            [this.name, this.acronym, this.code],
            [this.district, this.county, this.parish],
            [this.address],
            [this.zip_code, this.locality],
            [this.phone_number, this.mobile_number, this.fax_number],
            [this.email, this.tax_identification_number, this.status],
            [this.certificate_number, this.certificate_deadline],
        ];
    }

    /**
     * Returns the responsibles form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    responsiblesSchema(viewModel) {
        this.responsibles = {
            type:         'duallistbox',
            key:          'responsibles',
            label:        'form.field.responsibles-to-contact',
            size:         12,
            remoteSource: this.maintenanceCompaniesRepository.getUsers.bind(this.maintenanceCompaniesRepository, viewModel.model.id),
            required:     false,
            attributes:   {
                disabled: true,
            },
        };

        return [
            [this.responsibles],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            hidden:     viewModel.appContainer.authenticatedUser.belongsToMaintenanceCompany(false),
            action:     () => viewModel.redirectToRoute('entities.maintenance-companies.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}

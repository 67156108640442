import { bindable, inject }                 from 'aurelia-framework';
import { AppContainer }                     from 'resources/services/app-container';
import { BaseFormViewModel }                from 'base-form-view-model';
import { BooleanOption }                    from 'modules/administration/models/boolean-option';
import { ConfirmUserCreationDialog }        from 'resources/elements/html-elements/dialogs/confirm-user-creation-dialog';
import { DialogController, DialogService }  from 'aurelia-dialog';
import { FormSchema }                       from 'modules/entities/maintenance-technicians/form-schema';
import { MaintenanceTechniciansRepository } from 'modules/entities/maintenance-technicians/services/repository';

@inject(AppContainer, DialogController, DialogService, FormSchema, MaintenanceTechniciansRepository)
export class CreateMaintenanceTechnicianModal extends BaseFormViewModel {

    @bindable formId = 'entities-maintenance-technicians-creation-form-modal';

    @bindable alert;
    @bindable settings = {
        title:   'form.title.create-new-record',
        size:    'modal-full',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogController, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogController = dialogController;
        this.dialogService    = dialogService;
        this.formSchema       = formSchema;
        this.repository       = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'entities.maintenance-technicians.manage',
            'entities.maintenance-technicians.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema        = this.formSchema.schema(this);
            this.buttonsSchema = this.formSchema.modalButtonsSchema(this);

            // the maintenance company is pre-established in this case
            this.formSchema.entity_id.attributes = {
                disabled: true,
            };
        });
    }

    /**
     * Fetches data from server
     *
     * @param {entity_id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ entity_id }) {
        return new Promise((resolve, reject) => {
            this.model.entity_id = this.initialModel.entity_id = entity_id;

            resolve(true);
            reject(new Error('Error'));
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        return this.repository
            .create(this.model)
            .then(response => this.afterSubmitCallback(response))
            .then(response => response.status === true ? this.dialogController.ok(response.model.id) : false);
    }

    /**
     * Submits view form, and requests the creation of a new user
     */
    submitAndCreateUser() {
        this.model.create_user_id = BooleanOption.YES;

        this.dialogService
            .open({ viewModel: ConfirmUserCreationDialog })
            .whenClosed(response => {
                if (! response.wasCancelled) {
                    return this.submit().then(() => this.model.create_user_id = null);
                }

                this.model.create_user_id = null;
            });

        return false;
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
